import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { WebtoonListPage, WebtoonPage } from './pages/webtoon';

import { NewsPage } from './pages/news/news-page';
import { NotFound } from './pages/not-found';
import { ErrorPage } from './pages/error';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<WebtoonPage />} />
        <Route path="/newsList/:id" element={<NewsPage />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/error" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
