import instance from './instance';
import axios from 'axios';

async function fetchWebtoonInfoList() {
  try {
    const { data } = await instance.get('/GetWebtoonList');
    return data;
  } catch (error) {
    let errorMessage = '일시적인 네트워크 오류가 발생하였습니다.\n광고를 새로고침 해주세요.';

    if (axios.isAxiosError(error) && error.response) {
      const ErrorData = error.response.data;
      errorMessage = ErrorData.ResultMsg || errorMessage;
    }

    throw new Error(errorMessage);
  }
}

export default fetchWebtoonInfoList;
