import { useEffect, useRef } from 'react';
import { useScript } from '../../hooks/use-script';

export const CoupangBannerAd = ({ onClick }) => {
  const hasCoupangAd = useRef(false);

  useEffect(() => {
    window.focus();
    const onClickCoupangAd = () => {
      setTimeout(() => {
        if (document.activeElement.tagName === 'IFRAME') {
          onClick();
        }
      }, 1000);
    };

    window.addEventListener('blur', onClickCoupangAd);

    return () => window.removeEventListener('blur', onClickCoupangAd);
  }, []);

  useEffect(() => {
    if (hasCoupangAd.current) return;

    // eslint-disable-next-line no-undef
    new PartnersCoupang.G({
      id: 734461,
      template: 'carousel',
      trackingCode: 'AF1036751',
      subId: 'CPSconpick',
      width: '300',
      height: '300',
      tsource: '',
    });
    hasCoupangAd.current = true;
  }, []);

  return (
    <div>
      <script id="coupang-banner-ad"></script>
    </div>
  );
};
