import instance from './instance';
import axios from 'axios';

async function getNewsInfo(id) {
  try {
    const { data } = await instance.get(`/GetNewsCampaignInfo?newsId=${id}`);

    if (!data) {
      return null;
    }

    const { NewsInfo } = data;

    return NewsInfo;
  } catch (error) {
    let errorMessage = '일시적인 네트워크 오류가 발생하였습니다.\n광고를 새로고침 해주세요.';

    if (axios.isAxiosError(error)(error) && error.response) {
      const ErrorData = error.response.data;
      errorMessage = ErrorData.ResultMsg || errorMessage;
    }

    throw new Error(errorMessage);
  }
}

export default getNewsInfo;
