import './news-list.css';
import { React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import getNewsList from '../../apis/get-news-list';

export const NewsListPage = () => {
  const navigate = useNavigate();

  const [{ data, loading, error }, setNewsList] = useState({
    loading: false,
    data: null,
    error: null,
  });

  // mount시 campaign 정보 요청
  useEffect(() => {
    const onMount = async () => {
      try {
        setNewsList((prev) => ({ ...prev, loading: true }));
        const data = await getNewsList();
        setNewsList((prev) => ({ ...prev, loading: false, data }));
      } catch (error) {
        setNewsList((prev) => ({ ...prev, loading: false, error: error }));
      }
    };

    onMount();
  }, []);

  // 에러 발생 시 에러 페이지로 이동
  useEffect(() => {
    if (error) {
      navigate('/error', {
        state: { message: error.message },
      });
    }
  }, [error, navigate]);

  if (loading || !data) {
    return null;
  }

  return (
    <main className="w-full h-full overflow-y-scroll">
      <div id="conpick-news-container">
        <ul id="conpick-news">
          {data.map((el) => {
            return (
              <li
                className="conpick-news-list border-b border-[#EEEEEE]"
                key={`${el.id}+${el.Title}`}
                role="presentation"
                onClick={() => navigate(`/newsList/${el.Id}`)}
              >
                <div className="conpick-news-summary">
                  <div className="conpick-news-info">
                    <h5 className="conpick-news-title">{el.Title}</h5>
                    <span className="conpick-news-media">{el.MediaName}</span>
                    <span className="conpick-news-date">{el.PubDateTime}</span>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </main>
  );
};
