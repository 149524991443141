import { useLocation } from 'react-router-dom';

export const ErrorPage = () => {
  const { state } = useLocation();
  const message = state?.message || '잠시 후 다시 시도해주세요.';

  return (
    <div className="flex w-[100vw] h-[100vh] justify-center items-center text-center whitespace-pre-wrap">
      {message}
    </div>
  );
};
