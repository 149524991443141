import { React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import webtoonMainImg from '../../assets/webtoon-main.png';
import fetchWebtoonInfoList from '../../apis/get-webtoon-list';

export const WebtoonListPage = () => {
  const navigate = useNavigate();

  const [{ data, loading, error }, setWebtoonList] = useState({
    loading: false,
    data: null,
    error: null,
  });

  // mount시 campaign 정보 요청
  useEffect(() => {
    const onMount = async () => {
      try {
        setWebtoonList((prev) => ({ ...prev, loading: true }));
        const data = await fetchWebtoonInfoList();
        setWebtoonList((prev) => ({ ...prev, loading: false, data }));
      } catch (error) {
        setWebtoonList((prev) => ({ ...prev, loading: false, error: error }));
      }
    };

    onMount();
  }, []);

  // 에러 발생 시 에러 페이지로 이동
  useEffect(() => {
    if (error) {
      navigate('/error', {
        state: { message: error.message },
      });
    }
  }, [error, navigate]);

  if (loading || !data) {
    return null;
  }

  return (
    <div className="flex flex-col justify-center overflow-y-scroll h-full">
      {data.WebtoonInfoList?.map((webtoon) => (
        <div
          className="h-[80px] w-full px-[5px] relative border-b-[1px] border-[#EEEEEE] flex hover:cursor-pointer hover:bg-[#F1F2F4]"
          role="presentation"
          key={webtoon.Id}
          onClick={() => {
            navigate(`/?webtoonId=${webtoon.Id}`);
          }}
        >
          <img
            key={webtoon.Id}
            style={{ alignSelf: 'center' }}
            src={webtoon.ThumbnailImage}
            alt="웹툰 썸네일"
            className="w-[40%] h-full bg-black"
          />

          <div className="flex flex-col w-full justify-center p-[10px]">
            <span className="text-[14px]">{webtoon.Title}</span>
            <span className="text-[8px] line-clamp-3 text-[#707070]">
              {webtoon.Description + webtoon.Description}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};
