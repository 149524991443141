import fetchWebtoonInfo from '../../apis/get-webtoon-info';
import { useState, useEffect, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { ListPage } from '../list-page';
import { CoupangAd } from '../../components/coupang-ad/coupang-ad';

import UAParser from 'ua-parser-js';

export const WebtoonPage = () => {
  const navigate = useNavigate();
  let parser = new UAParser();
  let isIOS = parser.getOS().name === 'iOS';

  const [{ data, loading, error }, setWebtoonInfo] = useState({
    loading: false,
    data: null,
    error: null,
  });

  const [showCoupangAdWithTimer, setShowCoupangAdWithTimer] = useState(true);
  const [showCoupangBannerAd, setShowCoupangBannerAd] = useState(true);
  const [searchParams, _] = useSearchParams();

  const showNav = useRef(false);
  const startScroll = useRef(false);

  const id = searchParams.get('webtoonId');

  const readyForWebtoon = () => {
    setShowCoupangBannerAd(false);

    if (isIOS) {
      window.webkit?.messageHandlers?.apreward?.postMessage?.({
        action: 'startWebtoon',
      });
      window.webkit?.messageHandlers?.apreward?.postMessage?.({
        action: 'setBottomNavigationVisibility',
        bottomNavigationVisibility: false,
      });
    } else {
      window.apreward?.startWebtoon();
      window.apreward?.setBottomNavigationVisibility(false);
    }
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    const onMount = async () => {
      try {
        setWebtoonInfo((prev) => ({ ...prev, loading: true }));
        const data = await fetchWebtoonInfo(id);
        setWebtoonInfo((prev) => ({ ...prev, loading: false, data }));
      } catch (error) {
        setWebtoonInfo((prev) => ({ ...prev, loading: false, error: error }));
      }
    };

    onMount();
  }, [id]);

  // 에러 발생 시 에러 페이지로 이동
  useEffect(() => {
    if (error) {
      navigate('/error', {
        state: { message: error.message },
      });
    }
  }, [error, navigate]);

  if (!id) {
    return <ListPage />;
  }

  if (loading || !data) {
    return null;
  }

  return (
    <div style={{ maxWidth: '500px', margin: 'auto' }}>
      {/* <div id="coupang-banner"></div>; */}

      <div
        id="webtoonContainer"
        style={{
          overflow: 'scroll',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
        onScroll={(e) => {
          if (e.target.scrollTop > 1000 && !startScroll.current) {
            startScroll.current = true;
            if (isIOS) {
              window.webkit?.messageHandlers?.apreward?.postMessage?.({
                action: 'startScroll',
              });
            } else {
              window.apreward?.startScroll();
            }
          }

          if (
            e.target.scrollHeight - 50 < e.target.scrollTop + e.target.clientHeight &&
            !showNav.current
          ) {
            showNav.current = true;
            if (isIOS) {
              window.webkit?.messageHandlers?.apreward?.postMessage?.({
                action: 'setBottomNavigationVisibility',
                bottomNavigationVisibility: true,
              });
            } else {
              window.apreward?.setBottomNavigationVisibility(true);
            }
          }
        }}
      >
        {data?.WebtoonImages.map((imgSrc) => (
          <img
            style={{ borderStyle: 'none' }}
            alt="webtoon"
            width="100%"
            height="auto"
            src={imgSrc}
            key={imgSrc}
          />
        ))}
      </div>
      {/* {!data.prev && showCoupangAdWithTimer && (
        <CoupangAdWithTimer
          onClickVisitButton={readyForWebtoon}
          onClose={() => {
            if (isIOS) {
              window.webkit?.messageHandlers?.apreward?.postMessage?.({
                action: 'closeWebtoon',
              });

              return;
            }
            window.apreward?.closeWebtoon();
          }}
        />
      )} */}
      {!data?.Prev && showCoupangBannerAd && (
        <CoupangAd
          onClickVisitButton={readyForWebtoon}
          onClose={() => {
            readyForWebtoon();
            if (isIOS) {
              window.webkit?.messageHandlers?.apreward?.postMessage?.({
                action: 'closeWebtoon',
              });
            } else {
              window.apreward?.closeWebtoon();
            }
          }}
        />
      )}
    </div>
  );
};
